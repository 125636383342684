import React from "react";
import MainPc from "./components/pc/MainPc";
import MainTablet from "./components/tablet/MainTablet";
import MainMobile from "./components/Mobile/MainMobile";
function Main() {
  const goFin = () => {
    window.location.replace(
      "https://finfin996.com/register?agRefCode=d927980b-32b6-45cc-97a9-505e293d32a6"
    );
  };
  return (
    <>
      <div className="hidden lg:block mainPC cursor-pointer " onClick={goFin}>
        <MainPc />
      </div>
      <div className="hidden md:block lg:hidden cursor-pointer" onClick={goFin}>
        <MainTablet />
      </div>
      <div className=" block md:hidden cursor-pointer" onClick={goFin}>
        <MainMobile />
      </div>
    </>
  );
}

export default Main;
