import React from "react";
import "./Mainpc.css";
import pc01 from "./images/pc01.jpg";
import pc02 from "./images/pc02.jpg";
import pc03 from "./images/pc03.jpg";
import pc04 from "./images/pc04.jpg";
import pc05 from "./images/pc05.jpg";
import pc06 from "./images/pc06.jpg";

function MainPc() {
  const handleButtonClick = () => {
    window.location.href = "https://www.finnbet.com";
  };
  return (
    <>
      <div onClick={handleButtonClick}>
        <div>
          <img src={pc01} alt="Finnbet เว็บตรง PG" className="fullw" />
        </div>
        <div>
          <img src={pc02} alt="Finnbet เว็บตรง PG" className="fullw" />
        </div>
        <div>
          <img src={pc03} alt="Finnbet เว็บตรง PG" className="fullw" />
        </div>
        <div>
          <img src={pc04} alt="Finnbet เว็บตรง PG" className="fullw" />
        </div>
        <div>
          <img src={pc05} alt="Finnbet เว็บตรง PG" className="fullw" />
        </div>
        <div>
          <img src={pc06} alt="Finnbet เว็บตรง PG" className="fullw" />
        </div>
      </div>
    </>
  );
}

export default MainPc;
